
.slider-wrapper {
	position: relative;
	/* Slider */
	.slick-slider {
		margin-bottom: 0px;
		.slick-slide {
			outline: none;
			position: relative;
			img {
				margin: 0 auto;
				max-width: 100%;
			}
			.game-text {
				position: absolute;
				color: #fff;
			    top: 60%;
			    font-size: 16px;
			    width: 900px;
			    line-height: normal;
			    text-shadow: -1px 0px 2px #000;
				display: none;

			    // equal or less than 1199px
				@media ( max-width: $screen-md-max ) {
				    font-size: 16px;
				}

			    // equal or less than 991px
	    		@media ( max-width: $screen-sm-max ) {
			        font-size: 14px;
				    width: 720px;
			        text-align: right;
    				padding-left: 160px;
	    		}

	    		// equal or less than 767px
	   	 		@media ( max-width: $screen-xs-max ) {
 			        position: static;
				   	float: none;
				    display: block;
				    text-align: center;
				    width: 100%;
				    padding: 10px;
	   	 		}
			}
		}
	}

	.slider-dots-container {
	    position: absolute;
	    height: 40px;
	    z-index: 1;
        left: 50% !important;
        transform: translateX(-50%);
        bottom: -40px;

		@media ( max-width: $screen-md-max ) {
            bottom: -40px;
		}

	    // // equal or less than 991px
		// @media ( max-width: $screen-sm-max ) {
		//     position: static;
		//     float: right;
		//     margin-top: 20px;
		//     margin-left: 0px;
		// }

		// equal or less than 767px
 		@media ( max-width: $screen-xs-max ) {
		//     margin-top: 15px;
            bottom: -10px;
 		}

 		// equal or less than 479px
	    @media ( max-width: $screen-xs ) {
    		float: none;
		    display: block;
		    margin: 0px auto;
            bottom: -5px;
	    }

	}

	.slick-dots {
	    position: static;
	    bottom: 0px;
	    height: 40px;
	    margin-bottom: 0px;
	    line-height: 40px;

		li {
			width: 10px;
		    height: 10px;
		    margin: 0 5px;

		    // equal or less than 991px
			@media ( max-width: $screen-sm-max ) {
		        box-shadow: 0px 0px 30px 0px #000;
			}

		    button {
	    	    width: 10px;
			    height: 10px;
			    padding: 0;
		        background: #fff;
			    &:before {
			    	display: none;
			    }
			    &:focus,
			    &:hover {
			    	background: $brand-primary;
			    }
		    }
		    &.slick-active {
		    	button {
		    		background: $brand-primary;
		    	}
		    }
		}
	}
	.slick-prev,
	.slick-next {
		z-index: 1;
	    width: auto;
    	height: auto;
    	opacity: .6;
		margin-top: -30px;

		// equal or less than 767px
 		@media ( max-width: $screen-xs-max ) {
		   top: 40%;
 		}

    	&:hover,
    	&:focus {
    		opacity: 1;
    	}

		&:before {
	    	font-size: 60px;
			font-family: 'arrows-edge';
		}
	}
	.slick-prev {
		left: 0px;
		@extend .icon-chevron-thin-left;
	}
	.slick-next {
		right: 0px;
		@extend .icon-chevron-thin-right;
	}

	.slider-text {
		display: block;
        color: #fff;
        font-size: 26px;
        flex: 64% 1 0;
        // padding: 10px 0;
        line-height: 1.2;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding-right: 15px;

        @media ( max-width: $screen-md-max ) {
            flex: 70% 1 0;
            font-size: 18px;
            // padding: 15px 0;
        }

		@media ( max-width: $screen-xs-max ) {
			display: none;
		}
	}

    .slider-cta {
        flex: 31% 0 0;
        text-align: right;
        margin-left: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        @media ( max-width: $screen-md-max ) {
            flex: 25% 0 1;
            justify-content: flex-end;
        }

        @media ( max-width: $screen-xs-max ) {
            flex: 100%;
            justify-content: center;
            padding-bottom: 10px;
        }
    }

	.overlay {
	    // height: 105px;
	    width: 100%;
        transform: translateY(-100%);
	    position: absolute;
	    // margin-top: -105px;
	    // text-align: center;

	    // @include gradient-horizontal( rgba( 0, 0, 0, .0 ), rgba( 0, 0, 0, .75 ), 18%, 50% );
        background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.8) 35%, rgba(0, 0, 0, 0.3) 100%);

	    // equal or less than 1199px
		@media ( max-width: $screen-md-max ) {
            background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.9) 35%, rgba(0, 0, 0, 0.5) 100%);
		    // height: 85px;
    		// margin-top: -85px;
		}

		// equal or less than 991px
		@media ( max-width: $screen-sm-max ) {
		    // background: none;
		    // height: 65px;
    		// margin-top: -65px;
		}

	    // equal or less than 767px
	    @media ( max-width: $screen-xs-max ) {
	    	margin-top: 0px;
    		height: auto;
    		margin-bottom: 10px;
            position: relative;
            transform: none;
            text-align: center;
	    }

	    .container {
	    	position: relative;
			display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 15px 15px;

			@media ( max-width: $screen-xs-max ) {
				display: block;
                padding-top: 0;
			}
	    }

	    .logo {
		    height: 60px;
		    width: auto;
		    // margin-top: 25px;
            // margin-left: 25px;
		    // float: right;
	        display: inline-block;

	        // equal or less than 1199px
			@media ( max-width: $screen-md-max ) {
		        // margin-top: 15px;
    			// height: 50px;
                display: none;
			}

	        // equal or less than 991px
    		@media ( max-width: $screen-sm-max ) {
			    display: none;
    		}
	    }

	    .btn {
	    	@include ae-button;
	    	width: 255px;
		    height: 68px;
		    line-height: 48px;
		    font-size: 32px;
		    // float: left;
		    // margin-top: 20px;

			img {
				margin-left: 10px;
			    max-height: 36px;
			}

			// equal or less than 1199px
    		@media ( max-width: $screen-md-max ) {
		        // margin-top: 20px;
			    width: 230px;
			    height: 55px;
			    line-height: 40px;
			    font-size: 28px;
			    img {
			    	height: 60%;
			    }
			}

			// equal or less than 991px
    		@media ( max-width: $screen-sm-max ) {
			    // float: left;
			    // margin-top: 12px;
			    width: 150px;
			    height: 44px;
			    line-height: 32px;
			    font-size: 18px;
			    box-shadow: 0px 0px 30px 4px #000;
			    img {
			    	height: 60%;
			    }
    		}

			// equal or less than 767px
	        @media ( max-width: $screen-xs-max ) {
        	    box-shadow: 0px 0px 15px 2px #000;
        	    margin-top: 0px;
	    	}

	    	// equal or less than 479px
		    @media ( max-width: $screen-xs ) {
		    	float: none;
			    margin-bottom: 20px;
			    margin-top: 15px;
		    }


	    }
	}
	.divider {
		// equal or less than 767px
        @media ( max-width: $screen-xs-max ) {
    	    margin-bottom: 0px;
    	}
	}
}
