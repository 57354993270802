.boxes {
	text-align: center;
	color: #fff;
	margin-bottom: 30px;
	.box {
		display: inline-block;
		min-height: $content-bottom-height;
		height: auto;
		width: 32%;
		float: left;

		// equal or less than 767px
		@media ( max-width: $screen-xs-max ) {
			width: 100%;
			display: block;
			float: none;
		}

		a {
			display: inline-block;
			max-width: 100%;

			// equal or less than 767px
			@media ( max-width: $screen-xs-max ) {
				display: block;
			}
		}

		p {
			min-height: 140px;
		}

		img {
			max-width: 100%;
		}
		&.mobile-games {
			background: url( '../img/content-mobile-games-background.png' ) bottom left no-repeat;
			background-size: contain;

			// equal or less than 767px
			@media ( max-width: $screen-xs-max ) {
				background: none;
				img {
					max-width: 500px;
				}
			}

			// equal or less than 479px
			@media ( max-width: $screen-xs - 1 ) {
				img {
					max-width: 100%;
				}
			}

		}
		&.game-box {
			@include make-xs-column( 4 );
			margin: 10px 0px;
			font-weight: $font-weight-semibold;
			height: auto;
            overflow: hidden;

			// equal or less than 991px
			@media ( max-width: $screen-sm-max ) {
				@include make-sm-column( 6 );
			}

			// equal or less than 767px
			@media ( max-width: $screen-xs-max ) {
				@include make-xs-column( 12 );
			    margin-left: auto;
			    margin-right: auto;
			    float: none;
				// max-width: 380px;
			    display: block;

			    @include clearfix();
			    margin-bottom: 30px;

			}

            .game-box-ribbon-wrapper {
                position: relative;
                bottom: 18px;
            }

			img.game-image {
				border: 3px solid #606060;
				border: 3px solid rgba( 255, 255, 255, .3 );
				filter: none;
					-webkit-filter: grayscale(0);

				@include transition( all .3s ease-in-out);

				&:focus,
				&:hover {
					// filter: grayscale; /* IE6-9 */
					// filter: grayscale(.4); /* Firefox 35+ */
					// -webkit-filter: grayscale(.4); /* Google Chrome, Safari 6+ & Opera 15+ */
				    -webkit-filter: grayscale(40%);
				    -moz-filter: grayscale(40%);
				    -ms-filter: grayscale(40%);
				    -o-filter: grayscale(40%);
				    filter: grayscale(40%);
				    filter: gray; /* IE 6-9 */

					border: 3px solid #909090;
					border: 3px solid rgba( 255, 255, 255, .5 );
				}
			}

            .game-ribbon-image {
                position: absolute;
                left: 100%;
                bottom: 0;
            }

			span {
				margin-top: 10px;
			    font-size: 15px;
			}

			.left {
				float: left;
                max-width: 50%;
                white-space: nowrap;
			}

			.right {
                max-width: 50%;
				float: right;
				color: $brand-primary;
			}
		}
	}

	.divider-vert {
		height: $content-bottom-height;
		width: 2%;
		float: left;

		&:before {
  			@include divider-vert( #313131, $brand-primary, #000, $brand-primary );
  		}

  		// equal or less than 767px
  		@media ( max-width: $screen-xs-max ) {
			display: none;
		}
	}

	.game-box-ribbon {
		position: absolute;
        padding: 5px 10px 6px 10px;
        color: #fff;
        font-size: 12px;
        // bottom: 41px;
        bottom: 0px;

        // @media (max-width: $screen-md-max) {
        //     top: 115px;
        // }

        // @media (max-width: $screen-sm-max) {
        //     top: 140px;
        // }

        // @media (max-width: $screen-xs-max) {
        //     top: 70%;
        // }

        left: 2px;
    	background: #f90606; /* Old browsers */
    	background: -moz-linear-gradient(top, #f90606 0%, #f90606 50%, #b90202 51%, #f50000 100%); /* FF3.6-15 */
    	background: -webkit-linear-gradient(top, #f90606 0%,#f90606 50%,#b90202 51%,#f50000 100%); /* Chrome10-25,Safari5.1-6 */
    	background: linear-gradient(to bottom, #f90606 0%,#f90606 50%,#b90202 51%,#f50000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f90606', endColorstr='#f50000',GradientType=0 ); /* IE6-9 */

    	&:after {
    		// content: "";
    		// position: absolute;
    		// width: 0;
    		// height: 0;
    		// border-style: solid;
    		// border-width: 0 27px 27px 27px;
    		// border-color: transparent transparent #007bff transparent;
    	}
	}
	.ribbon-triangle {
		position: absolute;
        left: 165px;
        top: 150px;

        @media (max-width: $screen-md-max) {
            top: 115px;
        }

        @media (max-width: $screen-sm-max) {
            top: 140px;
        }

        @media (max-width: $screen-xs-max) {
            top: 70%;
        }
	}
}
