.games {
	.slider-wrapper {
		.divider {
		 	margin-top: 0px;
		}
		.slick-slider {
			max-height: 290px;
			.slick-slide {
				img {
					max-height: 290px;
				}
			}
		}
		.slick-prev,
		.slick-next {
			// equal or less than 767px
	 		@media ( max-width: $screen-xs-max ) {
			   top: 50%;
	 		}
	 	}
	}
	.overlay {
		background: none;
		margin-bottom: 0px;

		// equal or less than 767px
		@media ( max-width: $screen-xs-max ) {
			display: none;
		}
	}
	#content {

		// equal or less than 479px
		@media ( max-width: $screen-xs - 1 ) {
			padding-top: 50px;
		}

		.content-top {
			border-bottom: 1px solid $brand-primary;

			// equal or less than 767px
			@media ( max-width: $screen-xs-max ) {
				border: none;
			}

			@import 'navbar';

			.games-navbar {
				@include make-xs-column( 8 );
				border: none;

				// equal or less than 767px
				@media ( max-width: $screen-xs-max ) {
					@include make-xs-column( 12 );
				}

				.nav {
					width: 90%;

					// equal or less than 767px
					@media ( max-width: $screen-xs-max ) {
						border-bottom: 1px solid $brand-primary;
						width: 100%;
						padding-bottom: 15px;
	    				margin-bottom: 5px;
					}

					> li {
						vertical-align: middle;
	    				height: 64px;
	    				line-height: normal;

						// equal or less than 479px
	    				@media ( max-width: $screen-xs ) {
							line-height: 64px;
							width: 50%;
							float: left;
							> a {
								padding: 10px 10px;
							}
						}

						> a {
							padding-top: 0px;
							padding-bottom: 0px;
							color: $text-color;

						}

						&.active {
							a {
								color: $brand-primary;
							}
						}
					}
				}
			}
			.games-pagination {
				padding-left: 15px;
				padding-right: 15px;
				margin-bottom: 5px;
                min-height: 30px;
			}
		}
		.content-bottom {
			margin-top: 20px;
			height: auto;
			display: inline-block;
    		width: 100%;
			.boxes {
			    margin-left: -15px;
				margin-right: -15px;
				.pagination {
					display: none;
				}
			}
		}
	}
	#footer {
		.footer-top {
			display: block;
		}
	}
}
