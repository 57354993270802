.products {
	.slider-wrapper {
		.slick-slider {
			.slick-slide {
				h1,
				.game-text {
				    display: none;
				}
			}
		}
	}
}
