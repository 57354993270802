@mixin ae-button {
	background-color: #f00;
    border-radius: 0px;
    padding: 0;
    min-width: 130px;
    min-height: 44px;
    font-size: 20px;
    color: #fff;
    font-weight: $font-weight-semibold;
    border-width: 5px;
    border-top-color: #ff3535;
    border-left-color: #f00;
	border-right-color: #900;
	border-bottom-color: #900;
	outline: none;
	text-shadow: -1px -1px 1px #666;
	display: inline-block;

	&:focus,
	&:hover {
		background: darken( $brand-primary, 3% );
		border-bottom-color: darken( #ff3535, 5% );
        border-right-color: darken( #f00, 5% );
		border-left-color: darken( #900, 5% );
		border-top-color: darken( #900, 5% );
		@include gradient-directional( $start-color: darken( #900, 5% ), $end-color: #f00, $deg: 135deg );
		color: #fff;
		padding-top: 1px;
		padding-left: 2px;
	}
}

@mixin divider( $start: #000, $mid: #f00, $end: #363636, $basic: $start ) {
	background: $basic;
	background: -webkit-linear-gradient( left, $start 0%, $mid 33%, $mid 66%, $end 93%, $end 100% );
    background: -o-linear-gradient( left, $start 0%, $mid 33%, $mid 66%, $end 93%, $end 100% );
    background: linear-gradient( to right, $start 0%, $mid 33%, $mid 66%, $end 93%, $end 100% );
    background-repeat: no-repeat;
	height: 1px;
	display: block;
	content: " ";
	width: 100%;
}

@mixin divider-vert( $start: #313131, $mid: #f00, $end: #000, $basic: $start ) {
	background-color: $basic;
	background-image: -webkit-linear-gradient( top, $start 10%, $mid 55%, $end 105% );
    background-image: -o-linear-gradient( top, $start 10%, $mid 55%, $end 105% );
    background-image: linear-gradient( to bottom, $start 10%, $mid 55%, $end 105% );
    background-repeat: no-repeat;
	display: block;
	content: " ";
	width: 1px;
	height: 100%;
	margin: 0 auto;
}
