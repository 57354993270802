// COMMON
$brand-primary: 		#f00;
$brand-secondary: 		darken( $brand-primary, 5% );
$background-color:		#000;
$background-img:		'../img/background.png';
$header-background:		#363636;
// $footer-background:		#555;
$text-color:			#dadada;
$header-height:			206px;
$header-height-mobile:	auto;
$footer-height:			218px;
$footer-height-mobile:	auto;
$content-bottom-height:			357px;
$content-bottom-height-mobile:	auto;

// FONTS
$font-family-base:		'Exo 2', sans-serif;
$font-weight-normal: 	400;
$font-weight-semibold: 	600;
$font-weight-bold:	 	700;
$font-weight-extrabold:	800;

$slick-font-path: "../fonts/";
$slick-loader-path: "../img/";

// ACCORDION
// $accordion-heading-height:		55px;

// GRADIENT BUTTON
// $gradient-btn-start-color:		#0b931e;
// $gradient-btn-mid-color:		#6BF27E;
// $gradient-btn-end-color:		#0b931e;
