@font-face {
	font-family: 'arrows-edge';
	src:url('../fonts/arrows-edge.eot?pj5e6d');
	src:url('../fonts/arrows-edge.eot?#iefixpj5e6d') format('embedded-opentype'),
		url('../fonts/arrows-edge.ttf?pj5e6d') format('truetype'),
		url('../fonts/arrows-edge.woff?pj5e6d') format('woff'),
		url('../fonts/arrows-edge.svg?pj5e6d#arrows-edge') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'arrows-edge';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-chevron-thin-left:before {
	content: "\e60c";
}
.icon-chevron-thin-right:before {
	content: "\e60b";
}
.icon-dice:before {
	content: "\e60a";
}
.icon-fullscreen-alt:before {
	content: "\e609";
}
.icon-fullscreen-exit-alt:before {
	content: "\e608";
}
.icon-move-alt1:before {
	content: "\e607";
}
.icon-move:before {
	content: "\e606";
}
.icon-search:before {
	content: "\e605";
}
.icon-list:before {
	content: "\e603";
}
.icon-bars:before {
	content: "\e602";
}
.icon-facebook-official:before {
	content: "\e600";
}
.icon-twitter:before {
	content: "\e601";
}
.icon-menu:before {
	content: "\e604";
}

/* exo-2-regular - latin-ext_latin_cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local('Exo 2'), local('Exo2-Regular'),
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-regular.svg#Exo2') format('svg'); /* Legacy iOS */
}
/* exo-2-600 - latin-ext_latin_cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local('Exo 2 Semi Bold'), local('Exo2-SemiBold'),
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-600.svg#Exo2') format('svg'); /* Legacy iOS */
}
/* exo-2-700 - latin-ext_latin_cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local('Exo 2 Bold'), local('Exo2-Bold'),
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/exo-2-v4-latin-ext_latin_cyrillic-700.svg#Exo2') format('svg'); /* Legacy iOS */
}
