.individual {
	.content-container {
		background: none;
	}
	#content {
		padding-top: 0px;

		// equal or less than 479px
		@media ( max-width: $screen-xs - 1 ) {
			padding-top: 50px;
		}

		.content-top {
			border: none;
			background: url( '../img/individual-game-bg.jpg' ) center bottom no-repeat;
			background-size: contain;
			// min-height: 500px;
			position: relative;
			text-align: center;

			height: auto;
			@include clearfix();

			.game-wrapper {
				padding-top: 20px;
			    // height: 100%;
			    position: relative;
			    height: auto;
			    line-height: normal;
				@include make-sm-column-push( 1 );
				@include make-sm-column( 10 );
				.btn-fullscreen,
				.btn-back-to-games {
				    font-size: 20px;
				    text-transform: uppercase;
				    font-weight: $font-weight-semibold;
				    color: #fff;
				    text-shadow: -1px -1px 3px #000;
				    margin: 10px 0px 15px;
    				display: inline-block;

				    [class^="icon-"],
			  		[class*=" icon-"] {
    					margin-right: 5px;
    					font-size: 15px;
			  		}

		  			&:hover {
			  			@include transition( all .3s ease-in-out );

		  				opacity: 1;
		  				color: $brand-primary;
		  			}

				}
	  			.btn-back-to-games {
	  				display: block;
	  				margin: 0px auto 20px;
	  			}
	  			.btn-fullscreen {
	  				cursor: pointer;
	  			}

				.row {
					position: relative;
				}

                .mobile-game-link {
                    display: block;
                    position: relative;

                    > img {
                        display: block;
                    }

                    .mobile-game-link-overlay {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(255,255,255, .7);
                        left:0;
                        top:0;
                    }
                }

                .btn-game-link {
                    @include ae-button();
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 50%;
                    transform: translateX(-50%);
                    margin: 0;
                    height: 44px;
                    line-height: 32px;
                    font-size: 18px;

                    > img {
                            height: 60%;
                            margin-left: 10px;
                            max-height: 36px;
                    }
                }

				iframe {
					position: relative;
					max-width: 100%;
					@include box-shadow(0 0 13px 3px rgba(0,0,0,.5));
				}
			}
		}

		.content {
			padding-top: 0px;
			padding-bottom: 30px;
			border-bottom: 1px solid $brand-primary;
			.divider {
				margin-top: 0px;
				margin-bottom: 45px;
			}
		}

		.content-bottom {
			margin-top: 20px;
			height: auto;

			// equal or less than 991px
			@media ( max-width: $screen-sm-max ) {
				margin-bottom: 30px;
			}
			h4 {
				margin: 35px 0px 20px;
				text-transform: uppercase;
				font-size: 16px;
				font-weight: $font-weight-semibold;
			}
		}
		.boxes {
		    color: $text-color;
		    display: inline;

		    .box {
		    	// equal or less than 767px
				@media ( max-width: $screen-xs-max ) {
					@include make-xs-column( 12 );
				}
		    }
		}
		.game-description {
			@include make-xs-column( 8 );

			// equal or less than 991px
			@media ( max-width: $screen-sm-max ) {
				@include make-sm-column( 6 );
			}

			// equal or less than 767px
			@media ( max-width: $screen-xs-max ) {
				@include make-xs-column( 12 );
			}

			.title {
			    margin-top: 5px;
			    text-transform: uppercase;
			    font-weight: 600;
			    color: #fff;
			    margin-bottom: 20px;
			}
			.desc {
				padding: 0;
				p {
					padding: 0;
				}
			}
		}
	}
	#footer {
		.footer-top {
			display: none;
		}
	}

	&.fullscreen {
		overflow: hidden;
		#content {
			.content-top {
				.game-wrapper {
			    	position: fixed;
			    	top: 0px;
			    	right: 0px;
			    	bottom: 0px;
			    	left: 0px;
			    	width: 100%;
				    height: 100%;
				    z-index: 1;
				    background: #000;
				    padding: 0px;
				    .btn-back-to-games {
				    	display: none;
				    }
				}
			}
		}
    }
}
