// @import url(//fonts.googleapis.com/css?family=Exo+2:700,600,400);

body {
	font-family: $font-family-base;
	background: $background-color url( $background-img ) bottom center repeat-x;
	font-weight: $font-weight-normal;
	color: $text-color;
	a {
		color: $text-color;
		@include transition(color .3s ease-in-out);
		&:focus, &:hover {
			text-decoration: none;
			color: $brand-primary;
			outline: none;
		}
	}
	img {
		max-width: 100%;
	}
	#wrapper {

	}

	@import 'slider';

	.divider {
  		&:before {
  			@include divider( #000, $brand-primary, #000, $brand-primary );
  		}
  	}

  	.text-left {
		text-align: left;
	}
	.text-center {
		text-align: center;
	}
	.text-right {
		text-align: right;
	}

	&.index {
		.content-container {
			@include gradient-vertical( #313131, #000, 0%, 80% );
		}
	}
	.content-container {
		@include gradient-vertical( #313131, rgba( 0, 0, 0, 0 ), 0%, 80% );
	}


	.active {
		a {
			color: $brand-primary;

			&:hover,
			&:focus {
				color: $brand-primary;
			}
		}
	}

	.games-pagination {
	    list-style-type: none;
	    padding-left: 0px;
	    display: block;
	    margin-bottom: 0px;
        padding-top: 10px;
        font-weight: $font-weight-semibold;
        clear: both;
        text-align: right;
        line-height: normal;

        .back,
        .next {
        	font-variant: small-caps;
        	font-size: 17px;
			font-weight: normal;
        }

        .active {
        	a {
        		color: $brand-primary;
        	}
        }

		li {
			display: inline-block;
			padding: 0px 5px;
		}

	    a {
	    	color: #fff;

			&:hover {
				color: $brand-primary;
			}
	    }
	}

}

@import 'slick.scss';
@import 'slick-theme.scss';

@import 'page-games';
@import 'page-individual';
@import 'page-contact';
@import 'page-about-us';
@import 'page-products';

