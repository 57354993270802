.about-us {
	#content {
		padding-top: 0;
		color: #fff;

		h1 {
			margin-top: 0;
			margin-bottom: 20px;
			padding-bottom: 10px;
			border-bottom: 1px solid darken( $brand-secondary, 10% );
		}

		p {
			padding: 0;
			font-size: 18px;
			margin: 20px 0;
		}

		img {
			margin: 10px 0;
		}

		.content {
			display: inline-block;
			width: 100%;
			padding-top: 0;
			.sidebar-nav {
				@include make-xs-column( 3 );
				@include gradient-horizontal( #000, #444 );
				@include box-shadow( inset -30px -120px 200px -55px #000 );
				color: $text-color;

				ul {
					list-style-type: none;
					padding-left: 0;
					font-size: 16px;
					margin-bottom: 0;
					li {
						padding-top: 20px;
						&:after {
							height: 1px;
							display: block;
							content: " ";
							width: 100%;
							margin-top: 10px;
							background: $brand-primary;
							background: -webkit-linear-gradient( left, $brand-primary 0%, rgba( 0, 0, 0, 0 ) 100% );
						    background: -o-linear-gradient( left, $brand-primary 0%, rgba( 0, 0, 0, 0 ) 100% );
						    background: linear-gradient( to right, $brand-primary 0%, rgba( 0, 0, 0, 0 ) 100% );
						    background-repeat: no-repeat;

							// equal or less than 767px
				   	 		@media ( max-width: $screen-xs-max ) {
			 			        background: -webkit-linear-gradient( left, rgba( 0, 0, 0, 0 ) 0%, $brand-primary 50%, rgba( 0, 0, 0, 0 ) 100% );
							    background: -o-linear-gradient( left, rgba( 0, 0, 0, 0 ) 0%, $brand-primary 50%, rgba( 0, 0, 0, 0 ) 100% );
							    background: linear-gradient( to right, rgba( 0, 0, 0, 0 ) 0%, $brand-primary 50%, rgba( 0, 0, 0, 0 ) 100% );
				   	 		}
						}
					}
				}

				&:before {
					content: " ";
					display: block;
				    background: #000;
				    position: absolute;
				    top: 0;
				    right: 100%;
				    width: 300%;
				    height: 100%;
				}

				// equal or less than 767px
	   	 		@media ( max-width: $screen-xs-max ) {
 			        &:after {
						content: " ";
						display: block;
					    background: #444;
					    position: absolute;
					    top: 0;
					    left: 100%;
					    width: 15px;
					    height: 100%;
					}
 			    }

			}
			.about-us-content {
				@include make-xs-column( 9 );
				// equal or less than 991px
	   	 		@media ( max-width: $screen-sm-max ) {
 			        @include make-xs-column( 8 );
	   	 		}
	   	 		// equal or less than 767px
	   	 		@media ( max-width: $screen-xs-max ) {
 			        @include make-xs-column( 12 );
 			    }
			}
			.content-block {
				@include make-xs-column( 12 );
				padding-left: 65px;
			}
			.sidebar-nav,
			.content-block {
				padding-top: 70px;
				padding-bottom: 20px;
				min-height: 510px;
			}
			.sidebar-nav {
				// equal or less than 991px
	   	 		@media ( max-width: $screen-sm-max ) {
 			        @include make-xs-column( 4 );
	   	 		}
				// equal or less than 767px
	   	 		@media ( max-width: $screen-xs-max ) {
 			        @include make-xs-column( 12 );
 			        @include box-shadow( none );
 			        text-align: center;
 			        height: auto !important;
		            padding-top: 30px;
					padding-bottom: 45px;
	   	 		}
	   	 	}
	   	 	.content-block {
				// equal or less than 767px
	   	 		@media ( max-width: $screen-xs-max ) {
 			        @include make-xs-column( 12 );
 			        padding-top: 50px;
	   	 		}
			}
		}
	}
	.slider-wrapper {
		.slick-slider {
			.slick-slide {
				.game-text {
				    top: 75px;
					width: 35%;
					font-size: 22px;
					color: #000;
				    text-shadow: none;

				    // equal or less than 1199px
					@media ( max-width: $screen-md-max ) {
					    font-size: 20px;
					}

				    // equal or less than 991px
		    		@media ( max-width: $screen-sm-max ) {
				        font-size: 18px;
		    		}

		    		// equal or less than 767px
		   	 		@media ( max-width: $screen-xs-max ) {
	 			        position: absolute;
					    width: 40%;
					    text-align: left;
					    margin-bottom: 0;
					    margin-top: 0;
		   	 		}

		   	 		// equal or less than 720px
		   	 		@media ( max-width: 720px ) {
 			            top: 90px;
		   	 		}

		   	 		// equal or less than 479px
				    @media ( max-width: $screen-xs - 1 ) {
					    display: none;
				    }
				}
				h1 {
				    position: absolute;
				    top: 0;
				    margin: 25px 0 0;
				    font-weight: $font-weight-semibold;
				    color: #fff;
				    text-shadow: -1px -1px 1px #000;

				    // equal or less than 720px
		   	 		@media ( max-width: 720px ) {
 			            font-size: 28px;
    					margin-top: 45px;
		   	 		}

		   	 		// equal or less than 479px
				    @media ( max-width: $screen-xs - 1 ) {
					    display: none;
				    }
				}
				h1,
				.game-text {
				    display: none;
				}
			}
		}
	}
}
