#footer {
	height: $footer-height;
	position: relative;
	color: #999999;

	// equal or less than 767px
	@media ( max-width: $screen-xs-max ) {
		margin-bottom: 15px;
	    height: $footer-height-mobile;
	}

	a {
		color: #999999;

		&:focus,
		&:hover {
			color: $brand-primary;
		}
	}

	.divider {
		width: 40%;
		display: inline-block;

  		&:before {
  			@include gradient-horizontal( #000, $brand-primary );
  		}

  		// equal or less than 767px
  		@media ( max-width: $screen-xs-max ) {
			width: 100%;

			&:before {
	  			@include divider( #000, $brand-primary, #000, $brand-primary );
	  		}
		}
  	}

	.container {
		height: 100%;
	}

	.footer-wrap {
		position: relative;
		.games-pagination {
			a {
				color: #fff;
				&:hover {
					color: $brand-primary;
				}
			}
			.active {
	        	a {
	        		color: $brand-primary;
	        	}
	        }
		}
	}

	.footer-top {
		display: none;
	    padding-top: 20px;
	    max-height: 113px;
	    height: 100%;
	    text-align: right;

	    // equal or less than 767px
	    @media ( max-width: $screen-xs-max ) {
	    	padding-bottom: 20px;
	    }
	}

	.footer-bottom {
		position: absolute;
    	bottom: 0px;
    	width: 100%;
    	max-height: 105px;

    	.logo {
			height: 105px;
			display: inline-block;
		    // margin-right: 15px;
		}

		.footer-contacts {
			list-style-type: none;
			padding-left: 0px;
			display: inline-block;
			position: absolute;
			bottom: 15px;
			left: 220px;
			margin-bottom: 0px;
		}

		.copyright {
			position: absolute;
			bottom: 15px;
			right: 0px;
			font-size: 13px;
		}
	}

	// equal or less than 767px
	@media ( max-width: $screen-xs-max ) {
		.footer-wrap {
			text-align: center;
		}

		.footer-bottom {
			position: static;
	    	max-height: none;

			.logo {
				height: 130px;
				margin-right: 0px;
			}
			.footer-contacts,
			.copyright {
				position: static;
				display: block;
			}
			.footer-contacts {
				margin-bottom: 20px;
				position: static;
			}
		}
	}

}
