.header-container {
	border-bottom: 1px solid $brand-primary;
	background: $header-background url(../img/noisy-texture.png);

	@include box-shadow( inset 0 206px 206px -206px #000 );

	#header {
		// width: $wrapper-width;
		height: $header-height;
		margin-right: auto;
	  	margin-left: auto;
	  	position: relative;
	  	background: $header-background;

	  	@include gradient-horizontal( #000, $header-background, 18%, 45% );
	  	@include box-shadow( inset 0 206px 206px -206px #000 );

		// equal or less than 479px
	  	@media ( max-width: $screen-xs ) {
			min-height: $header-height - 64;
			height: $header-height-mobile;
		}

		&:before {
	  		content: " ";
	  		position: absolute;
	  		right: 100%;
	  		height: $header-height;
	  		width: 100%;
	  		background: #000 url(../img/noisy-texture.png);
	  	}
	  	.header-noise-background {
	  		background: url(../img/noisy-texture.png);
	  	}
	  	#logo {
	  		@include make-xs-column( 2.2 );

    		padding-right: 0px;

    		// equal or less than 767px
    		@media ( max-width: $screen-xs-max ) {
				display: none;
			}

	  		a {
	  			display: inline-block;
	  			position: absolute;
	  			bottom: 10px;

	  			img {
	  				max-height: $header-height;
	  				max-width: 100%;
	  			}

				// equal or less than 1199px
	  			@media ( max-width: $screen-md-max ) {
						max-width: 100%;
					}
	  		}

			height: $header-height;
	  	}
	  	#logo-mobile {
	  		display: none;

	  		// equal or less than 767px
	  		@media ( max-width: $screen-xs-max ) {
				display: inline-block;
			}

			a {
	  			display: inline-block;
	  			position: static;
	  			img {
	  				max-height: 142px;
	  				max-width: 100%;
	  			}

				// equal or less than 1199px
	  			@media ( max-width: $screen-md-max ) {
					max-width: 100%;
				}
	  		}
	  	}
	  	.header-menu-container {
	  		@include make-xs-column( 9.8 );

	  		// equal or less than 767px
	  		@media ( max-width: $screen-xs-max ) {
				@include make-xs-column( 12 );
			}

	  		padding-left: 0px;
	  		height: $header-height;
	  		position: relative;

			// equal or less than 479px
	  		@media ( max-width: $screen-xs ) {
				min-height: $header-height - 64;
				height: $header-height-mobile;
			}
	  	}
	  	.divider {
  		  	position: absolute;
			left: 0px;
		  	top: 142px;
		  	right: 0;
		  	margin-bottom: 0px;

			// equal or less than 479px
		  	@media ( max-width: $screen-xs ) {
				display: none;
		  	}

	  		&:before {
	  			@include divider( #000, $brand-primary, $header-background, $header-background );
	  		}
	  	}

	  	.main-menu {
	  		position: absolute;
	  		bottom: -1px;
	  		left: 0px;
	  		width: 70%;
  			text-align: center;
  			color: $text-color;
  			padding-bottom: 6px;

			// equal or less than 991px
  			@media ( max-width: $screen-sm-max ) {
				width: 100%;
			}

			// equal or less than 479px
			@media ( max-width: $screen-xs-max ) {
				position: static;
				display: none;
				padding-bottom: 1em;
			}

	  		@import 'navbar';
	  	}

	  	.navbar-header{
			position: absolute;
			top: 15px;
			right: 18px;
			color: $text-color;
		    opacity: .5;

			// equal or greater than 480px
			@media ( min-width: $screen-xs + 1 ) {
				display: none;
			}
			.navbar-toggle {
				margin: 0;
			    padding: 0;
			    font-size: 28px;
			}
		}
	  	.social-buttons {
	  		position: absolute;
	  		right: 15px;
	  		bottom: 64px;
	  		font-size: 32px;

	  		// equal or less than 767px
	  		@media ( max-width: $screen-xs-max ) {
	  			bottom: auto;
	  			right: 15px;
	  			top: 87px;
	  		}

	  		[class^="icon-"],
	  		[class*=" icon-"] {
	  			opacity: .5;
	  			margin-left: 5px;

	  			@include transition( all .3s ease-in-out );

	  			&:hover,
	  			&:focus {
	  				opacity: 1;
	  				color: $text-color;
	  			}
	  		}
	  	}

	}

}
