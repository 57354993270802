
.navbar {
	margin-bottom: 0px;
	font-weight: $font-weight-semibold;
	font-size: 20px;

	.nav {
		a{
			&:focus,
			&:hover {
				background: none;
				color: $brand-primary;
			}
		}
		.open {
			a {
				background: none;
			}
		}
	}

	.nav-justified {
		// equal or greater than 480px
		@media ( min-width: $screen-xs + 1 ) {
			> li {
			display: table-cell;
			width: 1%;
			}
			a {
				margin-bottom: 0;
			}
		}
	}
	.navbar-toggle {
		// equal or greater than 480px
		@media ( min-width: $screen-xs ) {
			display: none;
		}
	}
	.navbar-collapse {
		padding-left: 0px;
		padding-right: 0px;

		// equal or greater than 480px - equal or less than 767px
		@media ( min-width: $screen-xs + 1 ) and ( max-width: $screen-xs-max ) {
			display: block !important;
			height: auto !important;
			padding-bottom: 0;
			overflow: visible !important;
			width: auto;
			border-top: 0;
			box-shadow: none;
		}

		// equal or less than 479px
		@media ( max-width: $screen-xs ) {
			display: block;
		}
	}

	.dropdown-menu {
		font-size: 18px;
		border: none;
		border-radius: 0px;
		padding: 25px 0px;
		margin: 0px;
		margin-top: 7px;
		background: rgba( 0, 0, 0, .8 );

		li {
			a {
				color: $text-color;
				padding: 5px 30px;
				font-weight: $font-weight-semibold;
			}
		}

	}

	li {
		&.language-switcher {
			min-width: 70px;

			.dropdown-menu {
				width: 100%;
				z-index: 1;
				min-width: 0;
				text-align: center;

                > li {

                    &.active {
                        > a {
                            background-color: transparent;
                            border: 1px solid red;
                        }
                    }
                }
			}

            @media (max-width: $screen-xs) {
                > a {
                    display: none;
                }

                > .dropdown-menu {
                    width: auto;
                    font-size: 1em;
                    margin: 0;
                    padding: 0;
                    background: transparent;
                    box-shadow: none;
                    position: static;
                    float: none;

                    &, > li {
                        display: inline-block;
                    }

                    > li {
                        > a {
                            margin-left: .2em;
                            margin-right: .2em;
                            padding: .25em .5em;
                        }
                    }
                }
            }

            @media (min-width: $screen-xs-min + 1) {

                > .dropdown-menu {
                    > li {
                        &.active {
                            display: none;
                        }
                    }
                }
            }
		}
	}
}
