#content {
	padding-top: 80px;

	h1 {
		margin: 20px 0px 10px;
		font-size: 30px;
		font-weight: $font-weight-semibold;
		text-transform: uppercase;
		text-shadow: -1px -1px 3px #000;
	}

	p {
		margin: 10px 0px;
		font-size: 14px;
		text-shadow: 0px 0px 2px #000;
		padding: 0 25px;
	}

	@import 'boxes';

	.content-top {
		// height: 64px;
		line-height: 64px;

		// equal or less than 767px
		@media ( max-width: $screen-xs-max ) {
			height: 146px;
		}

		.search-box {
			@include make-xs-column( 4 );
			position: relative;
		    color: #666;

			// equal or less than 767px
			@media ( max-width: $screen-xs-max ) {
				@include make-xs-column( 12 );
			}

			.search-input,
			.search-submit {
				border: none;
			    height: 32px;
			    line-height: 32px;
			    padding: 0px 5px;
			    outline: none;
			}

			.search-input {
				background: none;
				border: 1px solid #666;
				width: 100%;
		    	color: #666;

		    	@include transition( all .3s ease-in-out );

		    	&:focus {
		    		border: 1px solid #999;
		    		color: #fff;
		    		background-color: #111;
		    		background-color: rgba( 0, 0, 0, 0.3 );
		    	}
			}

			.search-submit {
				width: 45px;
			    position: absolute;
			    right: 15px;
			    top: 15px;
			    background: none;
			    padding-top: 1px;

			    @include transition( all .3s ease-in-out );

			     &:focus,
			     &:hover {
			    	color: $brand-primary;
			    }
			}

		}

	}

	.content {
		padding: 30px 0px 70px;
		position: relative;

		.divider {
			margin: 30px auto;
			width: 80%;
	  		&:before {
	  			@include divider( rgba( 0, 0, 0, 0 ), $brand-primary, rgba( 0, 0, 0, 0 ), $brand-primary );
	  		}
	  	}

		.block-column {
			@include make-xs-column-push( 2 );
			@include make-xs-column( 4 );

			// equal or less than 767px
			@media ( max-width: $screen-xs-max ) {
				@include make-xs-column-push( 0 );
				@include make-xs-column( 12 );
			}
		}

		.labels {
			font-weight: $font-weight-semibold;
			position: relative;

			.required {
				color: #f00;
				margin-left: 3px;
			}

			// equal or less than 767px
			@media ( max-width: $screen-xs-max ) {
				text-align: left;
			}
		}
		.labels,
		.inputs {
			color: #888;
			line-height: 32px;
		}
		.contact-form {

			label {
				font-weight: $font-weight-semibold;
				margin-bottom: 5px;
				display: block;
			}

			input,
			select {
				height: 32px;
			}

			input,
			select,
			textarea {
				width: 100%;
				outline: none;
				background: none;
				border: 1px solid #666;
			    padding: 0px 5px;
			    margin: 0px;
			    border-radius: 0px;

		    	@include transition( all .3s ease-in-out );

		    	&:focus {
		    		border: 1px solid #999;
		    		color: #ffffff;
		    		background-color: #111111;
		    		background-color: rgba( 0, 0, 0, 0.3 );
		    	}
			}

			textarea {
				height: 130px;
				resize: none;
			    margin-bottom: 10px;
		        display: block;
			}

			label[for="online_gaming_yes"],
			label[for="online_gaming_no"] {
				width: 85px;
				display: inline-block;
				text-align: left;

				input {
					margin-right: 10px;
					width: 18px;
					float: left;

					.lt-ie9 & {
						border:none;
						width: 25px;
						height: 25px;
						position: relative;
						top: 5px;

						&:focus {
							background: none;
						}
					}
				}

			}


			.submit-button {
				@include make-xs-column-push( 3.5 );
				@include make-xs-column( 5 );

				// equal or less than 767px
				@media ( max-width: $screen-xs-max ) {
					@include make-xs-column-push( 2 );
					@include make-xs-column( 8 );
				}

				// equal or less than 479px
				@media ( max-width: $screen-xs - 1 ) {
					@include make-xs-column-push( 0 );
					@include make-xs-column( 12 );
				}

				button {
					@include ae-button;
					width: 100%;
					height: 50px;
				}

			}

		}
	}

	.content-bottom {
		height: $content-bottom-height;

		// equal or less than 767px
		@media ( max-width: $screen-xs-max ) {
			height: $content-bottom-height-mobile;
		}
	}

}
